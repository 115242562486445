import type { AUTHENTICATION_INTENT, AUTHENTICATION_PROVIDERS } from '~/constants/authentication'
import { AUTHENTICATION_TYPE } from '~/constants/authentication'

export default function () {
  const authenticationModal = useAuthenticationModal()
  const authenticationIntent = useAuthenticationIntent()
  const { authenticationProvider } = useAuthentication()

  function showLogin(params?: {
    intent?: AUTHENTICATION_INTENT
    provider?: AUTHENTICATION_PROVIDERS // Only support email for now
  }) {
    const { intent, provider } = params ?? {}

    authenticationModal.value = AUTHENTICATION_TYPE.Login

    authenticationIntent.value = intent

    authenticationProvider.value = provider
  }

  const isLoginVisible = computed(() => authenticationModal.value === AUTHENTICATION_TYPE.Login)

  return {
    isLoginVisible,
    showLogin,
  }
}
